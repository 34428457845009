@import '../styles/device';

.pl {
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #CACACA;
  font-size: px2vw(12);
  height: px2vw(52);
  background: white;
  &.borderTop {
    border-top: 1px solid #f4f4f4;
  }
}