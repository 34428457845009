@mixin pagePaddingBottom($paddingBottom) {
  padding-bottom: $paddingBottom;
  padding-bottom: calc(constant(safe-area-inset-bottom) + #{$paddingBottom});
  padding-bottom: calc(env(safe-area-inset-bottom) + #{$paddingBottom});
}
@mixin positionBottom($positionBottom) {
  bottom: $positionBottom;
  bottom: calc(constant(safe-area-inset-bottom) + #{$positionBottom});
  bottom: calc(env(safe-area-inset-bottom) + #{$positionBottom});
}
@mixin makeIphoneXButton($height, $paddingBottom: 0px) {
  height: $height;
  height: calc(constant(safe-area-inset-bottom) + #{$height});
  height: calc(env(safe-area-inset-bottom) + #{$height});
  padding-bottom: $paddingBottom;
  padding-bottom: calc(constant(safe-area-inset-bottom) + #{$paddingBottom});
  padding-bottom: calc(env(safe-area-inset-bottom) + #{$paddingBottom});
}
@function px2vw($px, $base:375) {
  @return $px / 375 * 100vw
}
