@import '../styles/device';

.tabsWithMenuWrapper {
    display: flex;
    .tabsWithMenu {
        flex: 1;
        .am-tabs-default-bar-tab {
            border-bottom: unset;
            height: px2vw(40);
            line-height: px2vw(40);
            padding: unset;
        }
    }
}

.tabsWithMenuPopover {
    .am-popover-arrow {
        display: none;
    }
    .am-popover-inner {
        box-shadow: unset;
    }
    position: fixed;
    top: px2vw(48) !important; //搜索框高度
    left: 0 !important;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.tabsWithMenuIcon {
    display: flex;
    align-items: center;
    .more {
        width: px2vw(17.7);
        height: px2vw(12.6);
        margin: 0 px2vw(13.2);
    }
}

.subTabs {
    position: relative;
    padding: 0 px2vw(10);
    .close {
        text-align: right;
        img {
            width: px2vw(14);
            height: px2vw(14);
            margin: px2vw(6);
        }
    }
    .tabs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        span {
            font-size: px2vw(14);
            padding: px2vw(13) px2vw(14);
            &.active {
                color: #0399EC;
            }
        }
    }
}
