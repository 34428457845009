@import '../styles/device';

.page {
  height: 100vh;
  width: 100vw;
  background: url('../images/invitation/bg@2x.png');
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo {
  width: px2vw(168);
  height: px2vw(60);
}
.slogan {
  font-size: px2vw(16);
  color: white;
  margin-top: px2vw(17);
  text-align: center;
}
.row {
  display: flex;
  align-items: center;
  border-radius: px2vw(5);
  background-color: white;
  padding-left: px2vw(15);
  padding-right: px2vw(10);
  height: px2vw(41);
  width: px2vw(252);
  margin-top: px2vw(10.5);
}
.codeLabel, .code {
  color: #4A4A4A;
  font-size: px2vw(13);
}
.codeLabel {
  margin-right: px2vw(20);
}
.form {
  margin-top: px2vw(40);
}
.sep {
  width: 1px;
  height: 3.5vw;
  background: #E6E6E6;
  margin: 0 px2vw(15);
}
.accountIcon {
  width: px2vw(16);
  height: px2vw(18);
}
.passwordIcon {
  width: px2vw(14.19);
  height: px2vw(17.03);
}
.captchaIcon {
  width: px2vw(16);
  height: px2vw(16);
}
.input {
  flex: 1;
  font-size: 13px;
  color: #4A4A4A;
}
.captcha {
  width: px2vw(80);
}
.sendCaptcha {
  border-radius: px2vw(5);
  width: px2vw(76);
  min-width: px2vw(76);
  height: px2vw(22);
  border: 1px solid #9B9B9B;
  color: #9B9B9B;
  font-size: px2vw(12);
  display: flex;
  align-items: center;
  justify-content: center;
}
.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: px2vw(5);
  background-color: #FFBF44;
  height: px2vw(41);
  width: px2vw(252);
  color: white;
  margin-top: px2vw(20);
  font-size: px2vw(14);
}