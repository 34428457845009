@import '../styles/_device.scss';

$px10: px2vw(10);
$px12: px2vw(12);
$px14: px2vw(14);

.searchWeapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 9999;

    .header {
        display: flex;
        background: #1DA9E9;
        //padding-bottom: $px10;
        .back {
            width: px2vw(32);
            display: flex;
            align-items: center;
            justify-content: center;
        }
        > form {
            flex: 1;
            background: #1DA9E9;
            padding-right: $px10;

            .am-search-input {
                border-radius: 20px;
                background-color: rgba(0, 0, 0, 0.4);

                input, .am-search-synthetic-ph-placeholder {
                    color: #fff;
                    font-size: $px14;
                }

                i {
                    background-image: url(../images/search_white@2x.png);
                }

            }
            .am-search-cancel {
                color: #fff;
                padding-left: $px10;
            }
        }
    }

    .content {
        padding: 0 px2vw(18);

        .history {
            margin-top: px2vw(25);

            .title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: px2vw(20);

                > img {
                    width: $px12;
                    height: $px14;
                }
            }
        }

        .label {
            font-size: $px14;
            color: #424242;
        }

        .keyWords {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: px2vw(16) 0;

            > span {
                margin-right: px2vw(8);
                margin-bottom: $px12;
                background: #F9F9F9;
                border: 1px solid #F4F4F4;
                font-size: $px12;
                color: #686868;
                padding: px2vw(4) $px12;
                border-radius: px2vw(20);

                &.hot {
                    color: #FA9976;
                }
            }
        }
    }

}


