@import '../styles/device';

.bg {
  background: #F4F4F4;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: px2vw(10);
  @include pagePaddingBottom(10px)
}
.btn {
  height: px2vw(50);
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: px2vw(17);
  color: #FFBF44;
}