@import '../styles/_device.scss';
$textColor: #414141;

.field {
  color: $textColor;
  margin-top: px2vw(10);
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: {
    top: px2vw(13);
    bottom: px2vw(13);
  };
  &:first-child {
    margin-top: 0;
  }
}
.fieldTitle {
  font-size: px2vw(16);
  margin-right: px2vw(18);
  padding-left: px2vw(13);
  span {
    padding-right: px2vw(8);
    position: relative;
    top: px2vw(2);
    color: #FF6275;
  }
}
.mainContent {
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
}
.camera {
  width: 8vw;
  height: 6.9vw;
}
.images {
  padding: 0 px2vw(5);
  display: flex;
  flex-wrap: wrap;
}
.image {
  margin-top: px2vw(14);
  margin-left: px2vw(12);
  width: px2vw(75);
  height: px2vw(75);
  border-radius: px2vw(10);
  border: 1px solid #F1F1F1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .previewImage {
    width: 100%;
    height: 100%;
  }
}
.video {
  font-size: px2vw(12);
}
.uploadMask {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}
.progress {
  font-size: px2vw(14);
  color: white;
}
.failed {
  background: white;
  padding: px2vw(4);
  border-radius: px2vw(10);
  font-size: px2vw(14);
  color: #FF6275;
}
.deleteBtn {
  position: absolute;
  right: -6px;
  top: -6px;
  z-index: 100;
}
.videoCamera {
  width: px2vw(32);
  height: px2vw(19.65);
}