@import '~normalize.css';

body {
  background: #F4F4F4;
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
input {
  padding: 0;
  border: none;
}
textarea {
  border: none;
  padding: 0;
  resize: none;
}
table {
  border-collapse: collapse;
}
input::-webkit-input-placeholder{
  color:#CECECE;
  font-size: px2vw(15);
}
textarea::-webkit-input-placeholder{
  color:#CECECE;
  font-size: px2vw(15);
}
.hiddenInput {
  position: fixed;
  top: -1000px;
  left: -1000px;
  visibility: hidden;
  opacity: 0;
}