@import '../styles/device.scss';

.button {
  position: fixed;
  z-index: 50;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 px2vw(-1) px2vw(4) 0 rgba(0,0,0,0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  @include makeIphoneXButton(px2vw(50));
  img {
    width: px2vw(35);
    height: px2vw(35);
  }
}
.text {
  font-size: px2vw(15);
  padding-left: px2vw(10);
  color: #FFBF44;
}