@import '../styles/device';

.detailRUActions {
  position: absolute;
  right: px2vw(15);
  top: px2vw(20);
  display: flex;
  align-items: center;
}
.collect {
  width: px2vw(20);
  height: px2vw(19);
  margin-right: px2vw(15);
}
.share {
  width: px2vw(18);
  height: px2vw(20);
}