@import '../styles/device';

.detailBlock {
  padding: 0 px2vw(14);
  background: white;
  margin-top: px2vw(10);
}
.header {
  display: flex;
  align-items: center;
  height: px2vw(40);
  border-bottom: 1px solid #F4F4F4;
}
.title {
  padding-left: px2vw(10);
  font-size: px2vw(14);
  font-weight: bold;
  color: #272727;
}
.content {
  padding-bottom: px2vw(10);
}