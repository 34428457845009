@import '../styles/device';

.pen {
  width: px2vw(15);
  height: px2vw(15);
}
.commentInputBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 px2vw(18);
  background: #FFFFFF;
  box-shadow: 0 px2vw(-1) px2vw(4) 0 rgba(0,0,0,0.08);
  @include makeIphoneXButton(px2vw(50));
}
.inputbox {
  display: flex;
  align-items: center;
  height: px2vw(33);
  width: px2vw(292);
  background: #FAF9F9;
  border: 1px solid #DDE1E4;
  border-radius: px2vw(100);
  padding-left: px2vw(15);
}
.textarea {
  font-size: px(14);
  flex: 1;
  padding-left: px2vw(10);
  height: 100%;
  background: transparent;
}
.textarea::-webkit-input-placeholder{
  color:#474B4E;
  font-size: px2vw(14);
}
.send {
  height: 100%;
  padding: 0;
  border: 0;
  background-color: white;
}