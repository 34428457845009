@import '../styles/_device.scss';

$textColor: #414141;
$placeholderColor: #CECECE;

.field {
  color: $textColor;
  background: white;
  padding: {
    left: px2vw(13);
    right: px2vw(13);
  };
}
.inner {
  height: px2vw(48);
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.sep {
    border-bottom: 1px solid #F4F4F4;
  }
}
.main {
  flex: 1;
}
.fieldTitle {
  min-width: px2vw(80);
  font-size: px2vw(16);
  span {
    padding-right: px2vw(8);
    position: relative;
    top: px2vw(2);
    color: #FF6275;
  }
  margin-right: px2vw(18);
}
.placeholder {
  font-size: px2vw(15);
  color: $placeholderColor;
}