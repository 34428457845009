@import '../styles/_device.scss';

.fixedButton {
    position: fixed;
    left: 50%;
    @include positionBottom(px2vw(80));
    transform: translateX(-50%);
    background: #03A8EC;
    color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.20);
    border-radius: px2vw(20);
    font-size: px2vw(14);
    padding: px2vw(10) px2vw(32);
}
