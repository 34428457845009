.page {
  height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
}
.miniqrcode {
  margin-top: 10vw;
  width: 60vw;
  height: 60vw;
}
.tips {
  margin-top: 5vw;
  line-height: 20px;
  font-size: 14px;
}