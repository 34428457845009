@import '../../styles/_device.scss';

$px10: px2vw(10);
$px12: px2vw(12);
$px14: px2vw(14);
$px38: px2vw(38);

.listItem {
    background: #fff;
    margin-top: $px10;
    padding: px2vw(15) px2vw(20);

    .top {
        display: flex;
        //justify-content: space-between;

        > img {
            width: $px38;
            height: $px38;
            border-radius: 50%;
        }
        .person {
            flex: 1;
            display: flex;
            //flex-flow: column;
            justify-content: space-between;
            margin-left: px2vw(7);

            .basic {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .basicTitle {
                    display: flex;
                    align-items: center;

                    .name {
                        font-size: px2vw(16);
                        color: #000;
                        margin-right: $px10;
                    }
                    .level {
                        font-size: $px10;
                        color: #F59430;
                        border: 1px solid #F59430;
                        padding: px2vw(1) px2vw(5) 0 px2vw(5);
                        border-radius: $px10;
                    }
                }

                .date {
                    font-size: px2vw(11);
                    color: #CACACA;
                }
            }
            .compony {
                font-size: $px12;
                color: #929292;
            }
        }

    }

    .center {
        font-size: $px14;
        line-height: px2vw(19);
        margin: px2vw(5) 0;
        .content {
            text-align: justify;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
        }
        .imgUrls {
            display: flex;
            flex-wrap: wrap;
            margin-top: $px10;
            overflow: hidden;

            .carouse {
                position: relative;
                margin-bottom: $px10;
                margin-right: px2vw(10);
                width: px2vw(105);
                height: px2vw(90);
                float: left;

                > img {
                    width: 100%;
                    height: 100%;
                }

                &:nth-child(3n) {
                    margin-right: unset;
                }

            }
        }
        .playMask {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.2);
            .playBtn {
                width: px2vw(25) !important;
                height: px2vw(25) !important;
                position: absolute;
                $half: px2vw(25 / 2);
                left: calc(50% - #{$half});
                top: calc(50% - #{$half});
            }
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        padding: 0 px2vw(20);

        .iconWithText {
            display: flex;
            align-items: center;
            span {
                margin-left: $px10;
                font-size: $px12;
                color: #929292;
            }
        }

    }

}

