@import '../../styles/_device.scss';

.page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    //background: #fff;
}

.pageTop {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: px2vw(48);
    background: #fff;
    border-bottom: 1px solid #F4F4F4;
}

.pageTabs {
    position: absolute;
    top: px2vw(48);
    right: 0;
    left: 0;
    background: #fff;
    border-bottom: 1px solid #F4F4F4;
    //box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.10);
}

.pageContent {
    position: absolute;
    top: px2vw(88);
    right: 0;
    left: 0;
    bottom: 0;
}

.listItemOperate {
    display: inline-block;
    padding: px2vw(4) px2vw(6);
    border-radius: px2vw(20);
    color: #FFBF44;
    border: 1px solid #FFBF44;
}
