@import '../styles/device';

.header {
  background: white;
  padding: px2vw(15) px2vw(14);
  padding-bottom: px2vw(10);
}
.titleC {
  display: flex;
  align-items: center;
  min-height: 6vw;
}
.circle {
  width: px2vw(14);
  height: px2vw(14);
  border: px2vw(4) solid #FFBF44;
  border-radius: 50%;
  &.blue {
    border-color: #00AAF2;;
  }
}
.title {
  font-family: PingFangSC-Medium;
  font-size: px2vw(17);
  color: #414141;
  padding-left: px2vw(8);
}
.subTitle {
  margin-top: px2vw(12);
  font-family: PingFangSC-Semibold;
  font-size: px2vw(16);
  color: #FF4C62;
}
.footer {
  margin-top: px2vw(14);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #9B9B9B;
  font-size: px2vw(12);
}