@import '../styles/_device.scss';

$textColor: #414141;

.page {
  @include pagePaddingBottom(px2vw(70));
}
.field {
  color: $textColor;
  margin-top: px2vw(10);
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: {
    left: px2vw(13);
    right: px2vw(13);
    top: px2vw(13);
  };
  &:first-child {
    margin-top: 0;
  }
}
.content {
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
}
.fieldTitle {
  font-size: px2vw(16);
  span {
    padding-right: px2vw(8);
    position: relative;
    top: px2vw(2);
    color: #FF6275;
  }
  margin-right: px2vw(18);
}

.textAreaC {
  min-height: px2vw(72);
  width: 100%;
  padding: px2vw(15);
  &.showBorder {
    border-bottom: 1px solid #F4F4F4;
  }
}
.textarea {
  width: 100%;
  height: 100%;
  font-size: px2vw(15);
  color: $textColor;
}
.sep {
  margin-top: px2vw(10);
}
.isHidden {
  padding-bottom: px2vw(15);
  display: flex;
  justify-content: flex-end;
}
.smallRow {
  display: flex;
}
.smallRowItem {
  flex: 1;
}
