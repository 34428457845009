@import '../../styles/_device.scss';

$px4: px2vw(4);
$px8: px2vw(8);
$px11: px2vw(11);
$px12: px2vw(12);
$px14: px2vw(14);
$px44: px2vw(44);

.listItem {
    padding: px2vw(15) $px12 px2vw(15) $px44;
    margin-top: px2vw(10);
    background: #fff;

    .itemContent {
        position: relative;

        .top {
            display: flex;
            justify-content: space-between;
            .title {
                font-size: px2vw(14);
                color: #414141;;
                // 不能超过一行
                max-width: px2vw(240);
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
            }
            .date {
                font-size: $px11;
                color: #CACACA;
            }
        }

        .center {
            display: flex;
            flex-flow: row wrap;
            margin: px2vw(8) 0;
            color: #929292;
            font-size: $px11;
            line-height: px2vw(18);

            > span {
                position: relative;
                padding: 0 $px8;
                &:before {
                    content: ' ';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: $px4;
                    height: $px4;
                    background-color: #929292;
                    border-radius: 4px;
                }
            }
        }

        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .salary {
                color: #06B2F1;
                font-size: px2vw(15);
            }
        }

        .index {
            position: absolute;
            top: 0;
            left: - px2vw(18);
            .cirleOut {
                position: relative;
                width: $px14;
                height: $px14;
                border-radius: 50%;
                .cirleIn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: #fff;
                    width: $px8;
                    height: $px8;
                    border-radius: 50%;
                }
            }
        }
    }
}
