@import '../../styles/_device.scss';

$px11: px2vw(11);
$px12: px2vw(12);
$px14: px2vw(14);

.listItem {
    padding: px2vw(15) px2vw(18);
    margin-top: px2vw(10);
    background: #fff;

    .itemContent {
        display: flex;

        img {
            width: px2vw(125);
            height: px2vw(90);
        }

        .introduce {
            display: flex;
            flex: 1;
            flex-flow: row wrap;
            margin-left: $px14;

            .title {
                font-size: $px14;
                color: #000;
                // 不能超过一行
                max-width: px2vw(200);
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
            }
            .tags {
                display: flex;
                flex-flow: row wrap;
                color: #929292;
                font-size: $px12;

                span {
                    padding-right: px2vw(20);
                }
            }

            .priceDate {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: space-between;
                .salary {
                    color: #E7A549;
                    font-size: px2vw(15);
                }
                .date {
                    font-size: $px11;
                    color: #CACACA;
                }
            }
        }
    }
}
