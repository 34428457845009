@import '../styles/_device.scss';

.toggle {
    display: flex;
    align-items: center;
    span {
        margin: 0 px2vw(10);
        font-size: px2vw(12);
        color: #929292;
    }
}
