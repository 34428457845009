@import '../styles/_device.scss';

.page {
  @include pagePaddingBottom(px2vw(50));
}
.carouseC {
  width: 100vw;
  height: px2vw(225)!important;
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img {
  max-width: 100%;
  height: auto;
}
.playBtn {
  width: px2vw(59);
  height: px2vw(59);
  position: absolute;
  $half: px2vw(59 / 2);
  left: calc(50% - #{$half});
  top: calc(50% - #{$half});
}
.phoneNumber {
  color: #FFBF44;
  font-size: px2vw(14);
}
.content {
  font-family: PingFangSC-Regular;
  font-size: px2vw(13);
  line-height: px2vw(20);
}
.infoRow {
  display: flex;
  align-items: center;
  margin-top: px2vw(13);
}
.infoItem {
  display: flex;
  align-items: center;
  font-size: px2vw(13);
  flex: 1;
  &:first-child {
    flex: 1.3;
  }
}
.infoKey {
  color: #929292;
}
.infoValue {
  color: #414141;
  padding-left: px2vw(10);
}
.content {
  margin-top: px2vw(13);
}