@import '../styles/device';

.page {
  @include pagePaddingBottom(px2vw(50));
}
.header {
  display: flex;
  background: white;
  padding: px2vw(15) px2vw(20);
}
.phoneNumber {
  color: #FFBF44;
  font-size: px2vw(14);
}
.avatar {
  width: px2vw(40);
  height: px2vw(40);
  border-radius: 50%;
}
.headerContent {
  padding-left: px2vw(9);
  flex: 1;
}
.jobName {
  font-size: px2vw(17);
}
.userName {
  font-size: px2vw(16);
  padding-left: px2vw(10);
}
.levelName {
  font-size: px2vw(13);
  color: #929292;
  padding-left: px2vw(8);
}
.personlabel {
  margin-top: px2vw(6);
  color: #929292;
  font-size: px2vw(12);
}
.headerFoot {
  margin-top: px2vw(16);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.expectedSalary {
  font-size: px2vw(15);
  color: #06B2F1;
}
.createdAt {
  font-size: px2vw(12);
  color: #9B9B9B;
}
.mainContent {
  margin-top: px2vw(10);
  background: white;;
  padding: 0 px2vw(20);
}
.block {
  background: white;
  padding-top: px2vw(18);
  border-top: 1px solid #EDEDED;
}
.blockHeader {
  font-size: px2vw(15);
  font-weight: bold;
}
.blockContent {
  margin-top: px2vw(10);
  font-size: px2vw(13);
  color: #555555;
  line-height: px2vw(20);
  padding-bottom: px2vw(18);
}   
.person {
  margin-top: px2vw(10);
  padding: {
    top: px2vw(15);
    bottom: px2vw(18);
    left: px2vw(12);
    right: px2vw(20);
  }
  display: flex;
  align-items: center;
  background: white;
}
.borderBottom {
  padding: {
    left: px2vw(12);
    right: px2vw(20);
  };
  background: white;
  >div {
    border-bottom: 1px solid #EDEDED;
  }
}