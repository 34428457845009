@import '../styles/device';

$checked-color: #FFBF44;

.selector {
  display: flex;
  align-items: center;
}
.title {
  color: #9B9B9B;
  font-size: px2vw(11);
}
.optionC {
  display: flex;
  align-items: center;
}
.optionText {
  margin-left: px2vw(6);
  font-size: px2vw(11);
  color: #9B9B9B;
}
.option {
  width: px2vw(16);
  height: px2vw(16);
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: px2vw(1.5) solid #9B9B9B;
  &.checked {
    border-color: $checked-color;
  }
}
.checker {
  width: px2vw(8);
  height: px2vw(8);
  background: $checked-color;
  border-radius: 50%;
}
.optionsGroup {
  width: px2vw(90);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: px2vw(8);
}