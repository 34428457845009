@import '../styles/device';

.similarSearchWrapper {
    padding: px2vw(8) px2vw(15) px2vw(10) px2vw(15);
}

.search {
    display: flex;
    align-items: center;
    padding: px2vw(6);
    line-height: px2vw(18);
    border-radius: px2vw(10);
    background: #F4F4F4;
}

.icon {
    width: px2vw(14);
    height: px2vw(14);
    margin: 0 px2vw(6);
}

.placeholder {
    font-size: px2vw(14);
    color: #CACACA;
}
