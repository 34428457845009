@import '../styles/_device.scss';

.page {
  @include pagePaddingBottom(px2vw(50));
  min-height: 100vh;
  background: white;
}
.carouseC {
  width: 100vw;
  height: px2vw(225)!important;
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img {
  width: 100%;
  height: auto;
}
.playBtn {
  width: px2vw(59);
  height: px2vw(59);
  position: absolute;
  $half: px2vw(59 / 2);
  left: calc(50% - #{$half});
  top: calc(50% - #{$half});
}
.main {
  position: relative;
  background: white;
  padding: px2vw(15);
  padding-bottom: px2vw(8);
}
.header, .username {
  display: flex;
  align-items: center;
}
.avatar {
  width: px2vw(37);
  min-width: px2vw(37);
  height: px2vw(37);
  border-radius: 50%;
}
.username {
  font-size: px2vw(17);
  margin-left: px2vw(7);
}
.levelName {
  margin-left: px2vw(10);
  padding: px2vw(1) px2vw(5);
  font-size: px2vw(10);
  border-radius: px2vw(7);
  color: #F59430;
  border: 1px solid #F59430;
}
.content {
  line-height: px2vw(20);
  padding: {
    top: px2vw(6);
    bottom: px2vw(4);
  }
}
.createdAt {
  font-size: px2vw(11);
  color: #CACACA;
  text-align: right;
}
.likeBar {
  margin-top: px2vw(10);
  display: flex;
  align-items: center;
  font-size: px2vw(12);
  color: #F56C30;
  height: px2vw(40);
  border-top: 1px solid #F4F4F4;
}
.likeBtn {
  width: px2vw(18);
  height: px2vw(16);
  margin-right: px2vw(9);
}
.comments {
  background: white;
  margin-top: px2vw(10);
  padding: 0 px2vw(15);
}
.commentsHeader {
  display: flex;
  align-items: center;
  height: px2vw(40);
  border-bottom: 1px solid #F4F4F4;
}
.commentsCount {
  font-size: px2vw(15);
  color: #272727;
  padding-left: px2vw(10);
  font-weight: bold;;
}
.sep {
  height: px2vw(10);
  background: #f4f4f4;
}
.bottomBar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}
.loading {
  padding: px2vw(10);
  display: flex;
  justify-content: center;
}