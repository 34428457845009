@import '../styles/device';

.comment {
  display: flex;
  padding-bottom: px2vw(16);
  &:first-child {
    .commentMain {
      border-top: none;
    }
  }
}
.header {
  height: px2vw(25);
  display: flex;
  align-items: center;
}
.avatar {
  margin-top: px2vw(14);
  width: px2vw(25);
  height: px2vw(25);
  border-radius: 50%;
}
.commentMain {
  border-top: 1px solid #f4f4f4;
  padding-top: px2vw(14);
  margin-left: px2vw(13);
  flex: 1;
}
.commentUser {
  font-size: px2vw(14);
}
.commentlevelName {
  font-size: px2vw(12);
  padding-left: px2vw(10);
  color: #929292;
}
.commentContent {
  color: #414141;
  font-size: px2vw(13);
  line-height: px2vw(20);
}
.replyBtn {
  margin-top: px2vw(10);
  width: px2vw(50);
  height: px2vw(22);
  background-color: #EDEDED;
  color: #414141;
  font-size: px2vw(13);
  padding: 0;
  border: 0;
  border-radius: px2vw(11);
}
.someMore {
  background: #EDEDED;
  padding: px2vw(7) px2vw(10);
  margin-top: px2vw(13);
  font-size: px2vw(14);
  color: #006E97;
  line-height: px2vw(20);
}
.smallName {
  max-width: px2vw(90);
}
.smallContent {
  color: #272727;
  padding-left: px2vw(6);
}
.autoHiddenText {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.modalBg {
  touch-action: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 800;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.window {
  height: 90vh;
  background: #F9F9F9;
  border-radius: px2vw(20) px2vw(20) 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.parentComment {
  background: white;
}
.windowTitle {
  height: px2vw(40);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.closeC {
  top: calc(50% - #{px2vw(16.5)});
  left: px2vw(14);
  position: absolute;
  padding: px2vw(8);
}
.closeBtn {
  width: px2vw(17);
  height: px2vw(17);
}
.secondLevelComment,
.myComments {
  padding-left: px2vw(18);
  padding-right: px2vw(40);
}
.secondLevelComment {
  position: relative;
  bottom: px2vw(10);
}
.myComments {
  flex: 1;
  width: 100%;
  height: 300px;
  overflow: auto;
}
.mainBorderBottom {
  border-bottom: 1px solid #EDEDED;
}
:global {
  .TechComment_modelBg {
    &.enter {
      opacity: 0;
      .TechComment_window {
        transform: translateY(100vh);
      }
    }
    &.enter-active {
      opacity: 1;
      transition: opacity 300ms;
      .TechComment_window {
        transform: translateY(0);
        transition: transform 300ms;
      }
    }
    &.exit {
      opacity: 1;
      .TechComment_window {
        transform: translateY(0);
      }
    }
    &.exit-active {
      opacity: 0;
      transition: opacity 300ms;
      .TechComment_window {
        transform: translateY(100vh);
        transition: transform 300ms;
      }
    }
  }
}