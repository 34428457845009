@import '../styles/device';

.detailContact {
  padding: px2vw(11) px2vw(14);
  background: white;
  display: flex;
}
.table {
  tr {
    vertical-align: top;
  }
  tr td {
    padding-top: px2vw(10);
  }
  tr:first-child td {
    padding-top: 0;
  }
}
.key {
  white-space: nowrap;
  padding: {
    left: px2vw(10);
    right: px2vw(14);
  }
  font-family: PingFangSC-Medium;
  font-size: px2vw(14);
  font-weight: bold;
  color: #272727;
}
.value {
  font-family: PingFangSC-Regular;
  font-size: px2vw(13);
  color: #414141;
}