@import '../../styles/_device.scss';

$px10: px2vw(10);
$px14: px2vw(14);
$px20: px2vw(20);
$px50: px2vw(50);

.pageContent {
    position: absolute;
    top: px2vw(48);
    right: 0;
    left: 0;
    bottom: 0;
}

.listItem {
    padding: px2vw(8) px2vw(12);
    background: #fff;
    margin-top: $px10;

    .introduction {
        padding-bottom: px2vw(15);
        border-bottom: 1px solid #F4F4F4;

        .position {
            display: flex;
            justify-content: space-between;
            line-height: px2vw(22);

            .name {
                color: #000;
                font-size: px2vw(16);
            }
            .salary {
                color: #06B2F1;
                font-size: $px14;
            }
        }
        .industry {
            color: #929292;
            font-size: $px14;
            line-height: $px20;

            span + span {
                &:before {
                    content: ' | ';
                }
            }
        }
    }

    .company {
        display: flex;
        align-items: center;
        padding: px2vw(9) 0;

        > img {
            width: $px50;
            height: $px50;
            border-radius: 50%;
        }

        .info {
            flex: 1;
            width: 0;
            margin: 0 px2vw(10);
            color: #929292;
            font-size: px2vw(13);
            line-height: px2vw(18);
            .content {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
    .operate {
        > span {
            padding: px2vw(4) px2vw(6);
            border-radius: $px20;
            color: #FFBF44;
            border: 1px solid #FFBF44;
        }
    }
}
