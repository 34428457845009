@import '../styles/device';

.separator {
    height: px2vw(10);
    background-color: #F4F4F4;
}

.listDataArea {
    background-color: #F4F4F4;
}

.footer {
    color: #CACACA;
    text-align: center;
    font-size: px2vw(12);
    height: px2vw(88);
    line-height: px2vw(60);
    border-top: 1px solid #f4f4f4;
    background-color: #fff;
    margin: -9px -15px -15px -15px;
}

.center {
    justify-content: center;
    padding-top: px2vw(24);
}
