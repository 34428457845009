@import '../../styles/_device.scss';

$px10: px2vw(10);
$px12: px2vw(12);
$px14: px2vw(14);
$px38: px2vw(38);

.pageContent {
    position: absolute;
    top: px2vw(48);
    right: 0;
    left: 0;
    bottom: 0;
}

.listItem {
    background: #fff;
    margin-top: $px10;
    padding: px2vw(16) px2vw(19);

    .top {
        display: flex;
        justify-content: space-between;

        .person {
            display: flex;
            > img {
                width: $px38;
                height: $px38;
                border-radius: 50%;
            }
            .basic {
                margin-left: px2vw(7);
                line-height: px2vw(19);;
                .name {
                    font-size: px2vw(16);
                    color: #000;
                    margin-right: $px10;
                }
                .level {
                    font-size: $px10;
                    color: #F59430;
                }
                .age {
                    font-size: $px12;
                    color: #929292;
                    span + span {
                        margin-left: px2vw(6);
                    }
                }
            }
        }
        .date {
            font-size: px2vw(11);
            color: #CACACA;
        }
    }

    .center {
        font-size: $px14;
        line-height: px2vw(19);
        margin: $px10 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
        text-align: justify;
    }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .salary {
            color: #06B2F1;
            font-size: $px14;
        }
    }

}
